"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.FormieForms = function FormieForms() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Global variables
   * @private
   */

  var forms = document.querySelectorAll('[data-fui-form]');
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if (forms.length) {
      forms.forEach(function (form) {
        var submitButton = form.querySelector('button.fui-btn.fui-submit');

        if (submitButton) {
          var submitButtonLabel = submitButton.getAttribute('data-button-text');

          if (submitButtonLabel) {
            submitButton.textContent = submitButtonLabel;
          }
        }
      });
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();