"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.Index = function Index() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var btnInfos = document.querySelector('.left-btn');
    var btnList = document.querySelector('.right-btn');
    var updateInfos = document.querySelector('.update-infos');
    var listInfos = document.querySelector('.list-infos');
    var userCta = document.querySelector('.current-user-wrapper');
    var overlaySite = document.querySelector('.overlay-site');
    var subMenuAccount = document.querySelector('.sub-menu-account');
    var inputsFile = document.querySelectorAll('[type="file"]');

    if (inputsFile.length) {
      inputsFile.forEach(function (inputFile) {
        inputFile.addEventListener('change', function (e) {
          if (inputFile.files[0].name.length > 0) {
            Swal.fire({
              title: 'Fichier téléchargé',
              text: 'Votre fichié a été téléchargé avec succès',
              icon: 'success',
              showCancelButton: false
            });
            console.log('inputFile', inputFile);
            console.log('parent', inputFile.parentElement);
            var fileName = inputFile.parentElement.querySelector('.input-file-name');
            console.log('fileName', fileName);

            if (fileName) {
              fileName.innerHTML = inputFile.files[0].name;
            }
          }
        });
      });
    }

    console.log('🎉 Index Module Initialized');

    if (userCta) {
      userCta.addEventListener('click', function (e) {
        e.preventDefault();
        subMenuAccount.classList.add('is-active');
        overlaySite.classList.add('is-active');
      });
      overlaySite.addEventListener('click', function (e) {
        subMenuAccount.classList.remove('is-active');
        overlaySite.classList.remove('is-active');
      });
    }

    if (btnInfos) {
      btnInfos.addEventListener('click', function (e) {
        e.preventDefault();
        console.log('clicking infos');
        btnList.classList.remove('is-active');
        btnInfos.classList.add('is-active');
        updateInfos.classList.add('is-active');
        listInfos.classList.remove('is-active');
      });
    }

    if (btnList) {
      btnList.addEventListener('click', function (e) {
        e.preventDefault();
        console.log('clicking list');
        updateInfos.classList.remove('is-active');
        listInfos.classList.add('is-active');
        btnInfos.classList.remove('is-active');
        btnList.classList.add('is-active');
      });
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();