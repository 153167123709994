"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.Register = function Register() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Global variables
   * @private
   */

  var searchableSelects = document.querySelectorAll('[data-select-searchable]');
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var formLogin = document.querySelector('.signup-form-wrapper');

    if (formLogin) {
      var submitBtn = formLogin.querySelector('.button');
      var informationBox = formLogin.querySelector('.information-box');
      var selectRegionRSEQ = formLogin.querySelector('#regionAdminisitrative');
      var selectsEcoles = formLogin.querySelectorAll('.ecoles');
      var usernameEl = document.querySelector('[name="username"]');

      if (selectRegionRSEQ && selectsEcoles.length > 0) {
        var region = selectRegionRSEQ.value;
        var selectEcoles = formLogin.querySelector(".ecoles[data-region=\"".concat(region, "\"]"));

        if (selectEcoles) {
          selectEcoles.classList.add('is-active');
        }

        selectRegionRSEQ.addEventListener('change', function (e) {
          e.preventDefault();
          var region = selectRegionRSEQ.value;
          selectsEcoles.forEach(function (select) {
            select.classList.remove('is-active');
          });
          var selectEcoles = formLogin.querySelector(".ecoles[data-region=\"".concat(region, "\"]"));

          if (selectEcoles) {
            selectEcoles.classList.add('is-active');
          }
        });
      }

      if (usernameEl) {
        var parent = usernameEl.parentNode;
        var errorsList = parent.querySelector('.errors');
        usernameEl.addEventListener('blur', function () {
          axios({
            url: "/actions/site-module/default/check-username?username=".concat(usernameEl.value),
            method: 'GET'
          }).then(function (res) {
            return res.data;
          }).then(function (data) {
            if (data.exists) {
              if (errorsList) {
                errorsList.innerHTML = '';
                var li = document.createElement('li');
                li.innerHTML = 'Ce nom d\'utilisateur est déjà utilisé';
                errorsList.appendChild(li);
              }

              submitBtn.classList.add('disabled');
            } else {
              if (errorsList) {
                errorsList.innerHTML = '';
              }

              submitBtn.classList.remove('disabled');
            }
          }).catch(function (error) {
            console.log('error :: ', error);
          });
        });
      }

      if (searchableSelects.length) {
        searchableSelects.forEach(function (select) {
          NiceSelect.bind(select, {
            searchable: true,
            searchtext: 'Rechercher...'
          });
        });
      }

      var searchableSelectsBoxes = document.querySelectorAll('.nice-select-search-box');

      if (searchableSelectsBoxes.length) {
        searchableSelectsBoxes.forEach(function (box) {
          var input = box.querySelector('input');
          input.placeholder = 'Rechercher...';
        });
      }
    } // Your code here


    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();