"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.Account = function Account() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Global variables
   * @private
   */

  var form = document.querySelector('[data-form-user]');
  var selectsSearchable = document.querySelectorAll('[data-select-searchable]');
  var accountTabs = document.querySelectorAll('[data-account-tab]');
  var headerUserMenuTrigger = document.querySelector('[data-user-menu-trigger]');
  var headerUserMenu = document.querySelector('[data-user-menu]');
  var instancesNiceSelect = [];
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if (form) {
      setupSchoolsSelects(form);
    }

    if (headerUserMenuTrigger && headerUserMenu) {
      handleHeaderUserMenu();
    }

    handleAccountTabs();
    return true;
  };

  var setupSchoolsSelects = function setupSchoolsSelects(currentForm) {
    var selectRegions = currentForm.querySelector('[data-form-regions]');
    var selectsSchools = currentForm.querySelectorAll('[data-form-schools]');

    if (selectsSearchable.length) {
      selectsSearchable.forEach(function (select) {
        instancesNiceSelect.push({
          el: select,
          instance: NiceSelect.bind(select, {
            searchable: true,
            searchtext: 'Rechercher'
          })
        });
      });
    }

    if (selectRegions && selectsSchools.length > 0) {
      var region = selectRegions.value;
      var selectSchool = currentForm.querySelector("[data-schools-region=\"".concat(region, "\"]"));

      if (selectSchool) {
        selectSchool.classList.remove('is-hidden');
      }

      selectRegions.addEventListener('change', function (e) {
        e.preventDefault();
        region = selectRegions.value;
        selectsSchools.forEach(function (select) {
          select.selectedIndex = 0;
          instancesNiceSelect.forEach(function (instance) {
            if (instance.el === select) {
              instance.instance.update();
            }
          });
          var parent = select.closest('.form-field-wrapper');

          if (parent) {
            parent.classList.add('is-hidden');
          }
        });
        selectSchool = currentForm.querySelector("[data-schools-region=\"".concat(region, "\"]"));

        if (selectSchool) {
          selectSchool.classList.remove('is-hidden');
        }
      });
    }
  };

  var handleAccountTabs = function handleAccountTabs() {
    if (accountTabs.length) {
      accountTabs.forEach(function (tab) {
        tab.addEventListener('click', function (e) {
          e.preventDefault();
          var slugTarget = tab.getAttribute('data-account-tab');

          if (slugTarget !== '') {
            var target = document.querySelector("[data-account-content=\"".concat(slugTarget, "\"]"));

            if (target) {
              var tabActive = document.querySelector('[data-account-tab].is-active');
              var contentActive = document.querySelector('[data-account-content].is-active');

              if (tabActive) {
                tabActive.classList.remove('is-active');
              }

              if (contentActive) {
                contentActive.classList.remove('is-active');
              }

              tab.classList.add('is-active');
              target.classList.add('is-active');
            }
          }
        });
      });
    }
  };

  var handleHeaderUserMenu = function handleHeaderUserMenu() {
    headerUserMenuTrigger.addEventListener('click', function (e) {
      e.preventDefault();
      headerUserMenuTrigger.classList.toggle('is-active');
      headerUserMenu.classList.toggle('is-active');
    });
    window.addEventListener('scroll', function () {
      if (headerUserMenuTrigger.classList.contains('is-active')) {
        headerUserMenuTrigger.classList.remove('is-active');
        headerUserMenu.classList.remove('is-active');
      }
    });
    document.addEventListener('click', function (e) {
      if (headerUserMenuTrigger.classList.contains('is-active')) {
        if (!headerUserMenuTrigger.contains(e.target) && !headerUserMenu.contains(e.target)) {
          headerUserMenuTrigger.classList.remove('is-active');
          headerUserMenu.classList.remove('is-active');
        }
      }
    });
  }; // Expose public methods & properties


  return {
    init: init
  };
}();