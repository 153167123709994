"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.ExportFilters = function ExportFilters() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Global variables
   * @private
   */

  var instance = null;
  var selectsRegions = document.querySelectorAll('[data-filter-regions]');
  var selectsSchools = document.querySelectorAll('[data-filter-schools]');
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    setupSchoolsSelects();
    setupRegionsSelects();
    return true;
  };

  var setupSchoolsSelects = function setupSchoolsSelects() {
    if (selectsSchools.length) {
      selectsSchools.forEach(function (select) {
        instance = NiceSelect.bind(select, {
          searchable: true,
          searchtext: 'Rechercher'
        });
        var options = select.querySelectorAll('option');

        if (instance) {
          options.forEach(function (option) {
            if (option.classList.contains('is-hidden')) {
              var instanceOption = instance.dropdown.querySelector("[data-value=\"".concat(option.value, "\"]"));

              if (instanceOption) {
                instanceOption.classList.add('is-hidden');
              }
            }
          });
        }
      });
    }
  };

  var setupRegionsSelects = function setupRegionsSelects() {
    console.log(selectsRegions);
    console.log(selectsSchools);

    if (selectsRegions.length && selectsSchools.length) {
      selectsRegions.forEach(function (select) {
        console.log(select);
        select.addEventListener('change', function (event) {
          var value = event.target.value;
          console.log(value);
          selectsSchools.forEach(function (select) {
            var options = select.querySelectorAll('option');
            options.forEach(function (option) {
              var instanceOption = instance.dropdown.querySelector("[data-value=\"".concat(option.value, "\"]"));

              if (value === '' || option.dataset.region === value || option.value === '' || option.value === 'null') {
                option.classList.remove('is-hidden');

                if (instanceOption) {
                  instanceOption.classList.remove('is-hidden');
                }
              } else {
                option.classList.add('is-hidden');

                if (instanceOption) {
                  instanceOption.classList.add('is-hidden');
                }
              }
            });
          });
        });
      });
    }
  }; // Expose public methods & properties


  return {
    init: init
  };
}();