"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

/* global site */
window.site = window.site || {};
/**
 * Main application class.
 * @class App
 * @static
 */

window.site.App = function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */

  var config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {}
  };
  /**
   * Initializes the class.
   * @public
   */

  var init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var version = document.querySelector('html').getAttribute('code-version');
    console.log(" \uD83C\uDF89 Code Version:%c ".concat(version, " "), 'color: #ff0000'); // Store application settings

    options = options || {};

    if (options.env) {
      config.env = options.env;
    }

    if (options.csrf) {
      config.csrf = options.csrf;
    }

    if (options.csrfName) {
      config.csrfName = options.csrfName;
    }

    if (options.locale) {
      config.locale = options.locale;
    }

    if (options.device) {
      config.device = options.device;
    }

    if (options.preview) {
      config.preview = options.preview;
    }

    if (options.general) {
      config.general = options.general;
    } // Initialize child classes


    if (_typeof(site.Account) === 'object') {
      site.Account.init();
    }

    if (_typeof(site.Validations) === 'object') {
      site.Validations.init();
    }

    if (_typeof(site.FormieForms) === 'object') {
      site.FormieForms.init();
    }

    if (_typeof(site.FileUpload) === 'object') {
      site.FileUpload.init();
    }

    if (_typeof(site.ExportFilters) === 'object') {
      site.ExportFilters.init();
    } // Shop


    if (_typeof(site.Product) === 'object') {
      site.Product.init();
    }

    console.table(options); // Return success

    return true;
  };
  /**
   * Getter for application config.
   * @public
   */


  var getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };
  /**
   * Expose public methods & properties.
   */


  return {
    init: init,
    config: getConfig
  };
}();