"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.FileUpload = function FileUpload() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Global variables
   * @private
   */

  var fileUploadButtons = document.querySelectorAll('[data-formie-file-upload] button');
  var fileUploadInputs = document.querySelectorAll('[data-formie-file-upload] input[type="file"]');
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if (fileUploadButtons.length && fileUploadInputs.length) {
      fileUploadButtons.forEach(function (button) {
        button.addEventListener('click', function (e) {
          e.preventDefault();
          var parent = button.parentElement;
          var input = parent.querySelector('input[type="file"]');
          console.log(input);
          input.click();
        });
      });
      fileUploadInputs.forEach(function (input) {
        input.addEventListener('change', function (e) {
          var parent = input.parentElement;
          var fileNameElement = parent.querySelector('.file-title .file-name');

          if (e.target.files.length) {
            var fileName = e.target.files[0].name;
            fileNameElement.parentElement.classList.add('is-active');
            fileNameElement.textContent = fileName;
          } else {
            fileNameElement.parentElement.classList.remove('is-active');
            fileNameElement.textContent = '';
          }
        });
      });
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();