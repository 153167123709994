"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.Validations = function Validations() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Global variables
   * @private
   */

  var forms = document.querySelectorAll('[data-form]');
  var $confirmIdentityField = document.querySelector('[data-confirm-user]');
  var $placeholderInputs = document.querySelectorAll('[data-has-placeholder]');
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if (forms.length) {
      forms.forEach(function (form) {
        switch (form.getAttribute('data-form')) {
          case 'login':
            validateLogin(form);
            break;

          case 'registration1':
            validateRegistration1(form);
            break;

          case 'registration2':
            validateRegistration2(form);
            break;

          case 'update-profile':
            validateRegistration2(form, true);
            break;
        }

        ;
      });
    }

    if ($confirmIdentityField) {
      var $confirmButton = document.querySelector('[action-is-confirmed]');
      $confirmIdentityField.addEventListener('change', function (e) {
        $confirmButton.disabled = !e.target.checked;
      });
    }

    if ($placeholderInputs) {
      $placeholderInputs.forEach(function ($input) {
        showPlaceholder($input);
      });
    }

    return true;
  };

  var validateLogin = function validateLogin(form) {
    var email = form.querySelector('[name="loginName"]');
    var password = form.querySelector('[name="password"]');
    var submit = form.querySelector('[type="submit"]');

    if (email && password) {
      var emailRegex = /^[a-zA-Z0-9.\+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      var validEmail = false;
      var validPassword = false;

      if (email.value !== '' && email.value.match(emailRegex)) {
        validEmail = true;
      }

      email.addEventListener('input', function () {
        var parent = email.closest('.form-field-wrapper');
        var error = parent.querySelector('.error-message');

        if (email.value === '') {
          parent.classList.add('has-error');
          error.textContent = 'Le champ est requis';
          validEmail = false;
        } else if (!email.value.match(emailRegex)) {
          parent.classList.add('has-error');
          error.textContent = 'Le courriel est invalide';
          validEmail = false;
        } else {
          parent.classList.remove('has-error');
          error.textContent = '';
          validEmail = true;

          if (validEmail && validPassword) {
            submit.removeAttribute('disabled');
          }
        }
      });
      password.addEventListener('input', function () {
        var parent = password.closest('.form-field-wrapper');
        var error = parent.querySelector('.error-message');

        if (password.value === '') {
          parent.classList.add('has-error');
          error.textContent = 'Le champ est requis';
          validPassword = false;
        } else {
          parent.classList.remove('has-error');
          error.textContent = '';
          validPassword = true;

          if (validEmail && validPassword) {
            submit.removeAttribute('disabled');
          }
        }
      });
    }
  };

  var validateRegistration1 = function validateRegistration1(form) {
    var email = form.querySelector('[name="email"]');
    var password = form.querySelector('[name="password"]');
    var submit = form.querySelector('[type="submit"]');

    if (email && password) {
      var emailRegex = /^[a-zA-Z0-9.\+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      var validEmail = false;
      var validPassword = false;

      if (email.value !== '' && email.value.match(emailRegex)) {
        validEmail = true;
      }

      email.addEventListener('input', function () {
        var parent = email.closest('.form-field-wrapper');
        var error = parent.querySelector('.error-message');

        if (email.value === '') {
          parent.classList.add('has-error');
          error.textContent = 'Le champ est requis';
          validEmail = false;
        } else if (!email.value.match(emailRegex)) {
          parent.classList.add('has-error');
          error.textContent = 'Le courriel est invalide';
          validEmail = false;
        } else {
          parent.classList.remove('has-error');
          error.textContent = '';
          validEmail = true;

          if (validEmail && validPassword) {
            submit.removeAttribute('disabled');
          }
        }
      });
      password.addEventListener('input', function () {
        var parent = password.closest('.form-field-wrapper');
        var error = parent.querySelector('.error-message');

        if (password.value === '') {
          parent.classList.add('has-error');
          error.textContent = 'Le champ est requis';
          validPassword = false;
        } else {
          parent.classList.remove('has-error');
          error.textContent = '';
          validPassword = true;

          if (validEmail && validPassword) {
            submit.removeAttribute('disabled');
          }
        }
      });
    }
  };

  var validateRegistration2 = function validateRegistration2(form) {
    var isEditPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var firstName = form.querySelector('[name="firstName"]');
    var lastName = form.querySelector('[name="lastName"]');
    var phone = form.querySelector('[name="fields[phone]"]');
    var jobFunction = form.querySelector('[name="fields[function][]"]');
    var region = form.querySelector('[name="fields[regions][]"]');
    var studentsNumber = form.querySelector('[name="fields[studentsNumber]"]');
    var submit = form.querySelector('[type="submit"]');
    var school = null;

    if (firstName && lastName && phone && jobFunction && region && studentsNumber && submit) {
      var validFirstName = firstName.value !== '';
      var validLastName = lastName.value !== '';
      var validPhone = phone.value !== '';
      var validJobFunction = jobFunction.value !== '';
      var validRegion = region.value !== '';
      var validStudentsNumber = studentsNumber.value !== '';
      var validSchool = isEditPage;
      firstName.addEventListener('input', function () {
        var parent = firstName.closest('.form-field-wrapper');
        var error = parent.querySelector('.error-message');
        submit.setAttribute('disabled', 'disabled');

        if (firstName.value === '') {
          parent.classList.add('has-error');
          error.textContent = 'Le champ est requis';
          validFirstName = false;
        } else {
          parent.classList.remove('has-error');
          error.textContent = '';
          validFirstName = true;

          if (validFirstName && validLastName && validPhone && validJobFunction && validRegion && validSchool && validStudentsNumber) {
            submit.removeAttribute('disabled');
          }
        }
      });
      lastName.addEventListener('input', function () {
        var parent = lastName.closest('.form-field-wrapper');
        var error = parent.querySelector('.error-message');
        submit.setAttribute('disabled', 'disabled');

        if (lastName.value === '') {
          parent.classList.add('has-error');
          error.textContent = 'Le champ est requis';
          validLastName = false;
        } else {
          parent.classList.remove('has-error');
          error.textContent = '';
          validLastName = true;

          if (validFirstName && validLastName && validPhone && validJobFunction && validRegion && validSchool && validStudentsNumber) {
            submit.removeAttribute('disabled');
          }
        }
      });
      studentsNumber.addEventListener('input', function () {
        var parent = studentsNumber.closest('.form-field-wrapper');
        var error = parent.querySelector('.error-message');
        submit.setAttribute('disabled', 'disabled');

        if (studentsNumber.value === '') {
          parent.classList.add('has-error');
          error.textContent = 'Le champ est requis';
          validStudentsNumber = false;
        } else {
          parent.classList.remove('has-error');
          error.textContent = '';
          validStudentsNumber = true;

          if (validFirstName && validLastName && validPhone && validJobFunction && validRegion && validSchool && validStudentsNumber) {
            submit.removeAttribute('disabled');
          }
        }
      });
      phone.addEventListener('input', function () {
        var parent = phone.closest('.form-field-wrapper');
        var error = parent.querySelector('.error-message');
        submit.setAttribute('disabled', 'disabled');

        if (phone.value === '') {
          parent.classList.add('has-error');
          error.textContent = 'Le champ est requis';
          validPhone = false;
        } else if (phone.value.replace(/\D/g, '').length < 10) {
          parent.classList.add('has-error');
          error.textContent = 'Le numéro de téléphone est invalide';
          validPhone = false;
        } else {
          parent.classList.remove('has-error');
          error.textContent = '';
          validPhone = true;

          if (validFirstName && validLastName && validPhone && validJobFunction && validRegion && validSchool && validStudentsNumber) {
            submit.removeAttribute('disabled');
          }
        }
      });
      jobFunction.addEventListener('change', function () {
        var parent = jobFunction.closest('.form-field-wrapper');
        var error = parent.querySelector('.error-message');
        submit.setAttribute('disabled', 'disabled');

        if (jobFunction.value === '') {
          parent.classList.add('has-error');
          error.textContent = 'Le champ est requis';
          validJobFunction = false;
        } else {
          parent.classList.remove('has-error');
          error.textContent = '';
          validJobFunction = true;

          if (validFirstName && validLastName && validPhone && validJobFunction && validRegion && validSchool && validStudentsNumber) {
            submit.removeAttribute('disabled');
          }
        }
      });
      region.addEventListener('change', function () {
        var parent = region.closest('.form-field-wrapper');
        var error = parent.querySelector('.error-message');
        submit.setAttribute('disabled', 'disabled');

        if (region.value === '') {
          parent.classList.add('has-error');
          error.textContent = 'Le champ est requis';
          validRegion = false;
        } else {
          parent.classList.remove('has-error');
          error.textContent = '';
          validRegion = true;
          school = form.querySelector("[data-schools-region=\"".concat(region.value, "\"] select"));
          school.addEventListener('change', function () {
            var parent = school.closest('.form-field-wrapper');
            var error = parent.querySelector('.error-message');
            submit.setAttribute('disabled', 'disabled');

            if (school.value === '') {
              parent.classList.add('has-error');
              error.textContent = 'Le champ est requis';
              validSchool = false;
            } else {
              parent.classList.remove('has-error');
              error.textContent = '';
              validSchool = true;

              if (validFirstName && validLastName && validPhone && validJobFunction && validRegion && validSchool && validStudentsNumber) {
                submit.removeAttribute('disabled');
              }
            }
          });
        }
      });
    }
  };

  var showPlaceholder = function showPlaceholder(input) {
    input.addEventListener('input', function (e) {
      var inputParent = input.closest('.form-field');

      if (input.value != '') {
        inputParent.classList.add('is-filled');
      } else {
        inputParent.classList.remove('is-filled');
      }
    });
  }; // Expose public methods & properties


  return {
    init: init
  };
}();